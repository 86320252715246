<script lang="ts">
  type CheckboxValue = string | number;
  interface Props {
    class?: string;
    disabled?: boolean;
    name: string;
    value: CheckboxValue;
    state: boolean;
  }

  let {
    class: classes = "",
    disabled = false,
    name,
    value,
    state = $bindable(false),
  }: Props = $props();
</script>

<input bind:checked={state} type="checkbox" class={classes} {disabled} {name} {value} />

<style lang="postcss">
  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;

    &::before {
      border-radius: 0;
      background-color: var(--_box-fill, transparent);
      box-shadow:
        inset 0 0 0 1px,
        inset 0 0 0 4px var(--_box-outline, transparent);
      content: "";
      display: inline-block;
      margin-inline-end: var(--s-1);
      transform: translateY(17.5%);
      width: 1em;
      height: 1em;
    }

    &:checked::before {
      --_box-fill: currentcolor;
      --_box-outline: var(--glod);
    }
  }
</style>
