<script lang="ts">
  // Implementation:
  // https://developers.google.com/tag-platform/security/guides/consent?consentmode=basic#tag-manager
  // Some cookie info from google:
  // https://business.safety.google/adscookies/

  const containerId = "GTM-PR49RXC"; // "AW-757765087";

  interface Props {
    track?: boolean;
  }

  let { track = false }: Props = $props();

  let addGtmScript = $state(false);
  let toggleScript: ReturnType<typeof setTimeout> | undefined;
  let toggleConsent: ReturnType<typeof setTimeout> | undefined;
  let consent = $state(false);

  const trackTrue = () => {
    // Add script
    if (toggleScript) {
      clearTimeout(toggleScript);
    }

    addGtmScript = true;

    // Consent after 2 second
    if (toggleConsent) {
      clearTimeout(toggleConsent);
    }

    toggleConsent = setTimeout(() => {
      consent = true;
    }, 2000);
  };

  const trackFalse = () => {
    // Remove consent
    if (toggleConsent) {
      clearTimeout(toggleConsent);
    }

    consent = false;

    // Remove script after 2 seconds
    if (toggleScript) {
      clearTimeout(toggleScript);
    }

    toggleScript = setTimeout(() => {
      addGtmScript = false;
    }, 2000);
  };

  $effect(() => {
    if (track) trackTrue();
    else trackFalse();
  });
</script>

<svelte:head>
  <script>
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      dataLayer.push(arguments);
    }

    gtag("consent", "default", {
      ad_user_data: "denied",
      ad_personalization: "denied",
      ad_storage: "denied",
      analytics_storage: "denied",
      wait_for_update: 500,
    });
    dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
  </script>

  {#if consent}
    <script>
      gtag("consent", "update", {
        ad_user_data: "granted",
        ad_personalization: "granted",
        ad_storage: "granted",
        analytics_storage: "granted",
      });
    </script>
  {:else}
    <script>
      gtag("consent", "update", {
        ad_user_data: "denied",
        ad_personalization: "denied",
        ad_storage: "denied",
        analytics_storage: "denied",
      });
    </script>
  {/if}

  {#if addGtmScript}
    <script async src="https://www.googletagmanager.com/gtm.js?id={containerId}"></script>
  {/if}
</svelte:head>
