<script lang="ts">
  // GDPR consent implementation

  interface Props {
    // https://developers.facebook.com/docs/meta-pixel/implementation/gdpr/
    track?: boolean;
  }

  let { track = false }: Props = $props();
  let addFbScript = $state(false);

  // Use effect because
  // addFbScript should not toggle off
  // (consent to tracking logic below)
  $effect(() => {
    if (track) addFbScript = true;
  });
</script>

<svelte:head>
  {#if addFbScript}
    <script>
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

      fbq("consent", "revoke");
      fbq("init", "223445949151567");
      fbq("track", "PageView");
    </script>

    <script async src="https://connect.facebook.net/en_US/fbevents.js"></script>
  {/if}

  {#if addFbScript && track}
    <script>
      fbq("consent", "grant");
    </script>
  {:else if addFbScript && !track}
    <script>
      // if fb script is added, but consent is later revoked
      fbq("consent", "revoke");
    </script>
  {/if}
</svelte:head>
