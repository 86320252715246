<script module lang="ts">
  const minConsent: ConsentLevel[] = [1];
  let chosenLevels: ConsentAnswer = $state(minConsent);
  let chosenNotConfirmedLevels: boolean[] = $state([true, false, false, false]);
  let prevAnswer: ConsentAnswer | undefined;
  let logTimeout: ReturnType<typeof setTimeout> | undefined;
</script>

<script lang="ts">
  import { onMount } from "svelte";
  import FacebookPixelScript from "./FacebookPixelScript.svelte";
  import GoogleTagManager from "./GoogleTagManager.svelte";
  import { afterNavigate } from "$app/navigation";
  import storageAvailable from "$lib/utils/storageAvailable";
  import consentAnswerStore, {
    administrateCookieChoice,
    versionDate as version,
    type ConsentLevel,
    type ConsentAnswer,
  } from "$lib/cookieConsent.svelte";
  import Checkbox from "$lib/InputCheckbox.svelte";

  const storageLevelKey = `cookieLevels${version.replace(/-/g, "")}`;
  // Cookie consent levels
  const consentLevels = new Map([
    [
      1,
      {
        name: "necessary",
        label: "Nødvendige",
      },
    ],
    // ["statistics", 2],
    [
      4,
      {
        name: "marketing",
        label: "Markedsføring",
      },
    ],
  ]);

  const storeTrackingLocalStorage = (value: "true" | "false" | "") => {
    // Use localStorage and the following key for Google consent mode
    if (hasLocalStorage) localStorage.setItem("consentGranted", value);
  };

  const setChosenNotConfirmedLevels = (consentAnswer: ConsentAnswer) => {
    return chosenNotConfirmedLevels.map((_: boolean, i: number) =>
      i > 0 ? consentAnswer.includes((i + 1) as ConsentLevel) : true
    );
  };

  // const logConsentChoice = async (choice: ConsentAnswer) => {
  //   if (typeof AbortSignal !== "undefined" && typeof AbortSignal.timeout !== "undefined")
  //     try {
  //       const res = await fetch("/api/consent", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Credentials: "same-origin",
  //           // Cookie: document.cookie,
  //         },
  //         body: JSON.stringify({
  //           choice: Array.from(consentLevels.keys())
  //             .filter((level) => choice.includes(level as ConsentLevel))
  //             .map((level) => {
  //               const levelVals = consentLevels.get(level);
  //               return levelVals ? levelVals.name : "";
  //             })
  //             .join(", "),
  //           version,
  //           datetime: new Date().toLocaleString("sv"),
  //         }),
  //         signal: AbortSignal.timeout(30000),
  //       });
  //
  //       if (!res.ok) {
  //         throw new Error("Bad fetch response");
  //       } else {
  //         const data = await res.json();
  //         // TODO: watch out, should not log data in prod
  //         if (data) console.table(data);
  //       }
  //     } catch (error) {
  //       console.warn("Log error", error);
  //     }
  // };

  const setConsentAnswer = (value: "true" | "false" | "null") => {
    clearTimeout(logTimeout);

    // if "true", set all levels
    if (value === "true") {
      chosenLevels = Array.from(consentLevels.keys()) as ConsentAnswer;
      chosenNotConfirmedLevels = setChosenNotConfirmedLevels(chosenLevels);
    } else {
      if (value === "null") chosenNotConfirmedLevels = setChosenNotConfirmedLevels(minConsent);

      chosenLevels = chosenNotConfirmedLevels
        .map((state: boolean, i: number) => state && i + 1)
        .filter((x) => x) as ConsentAnswer;
    }

    consentAnswerStore.set(chosenLevels);

    if (hasLocalStorage) {
      localStorage.setItem(storageLevelKey, JSON.stringify(chosenLevels));
    }

    // TODO: call when DB for logging is ready
    // logTimeout = setTimeout(() => {
    //   logConsentChoice(chosenLevels);
    // }, 2000);
  };

  let {
    banner = false,
    alternatives = {
      null: "Avslå",
      false: "Bruk valgte",
      true: "Godta alle",
    },
    class: classes = "",
    children,
  }: {
    // Show as cookie banner, else: normal flow
    banner?: boolean;
    // Button alternatives to user
    alternatives?: Record<"false" | "true" | "null", string>;
    class?: string;
    children?: import("svelte").Snippet;
  } = $props();
  let hasNavigatedAfterAnswer = $state(false);
  let hasPreviouslyAnswered = $state(false);
  let hasAnswered = $state(false);
  let visuallyHidden = $state(true);
  let hasLocalStorage = $state(false);
  let hasFunctionalRequirements = $state(false);
  let hasCookiesEnabled = false;
  let uaNotBlockTracking = false;
  let trackingAccepted = $state(false);
  let hideTimeout: ReturnType<typeof setTimeout> | undefined = $state();
  let removeBannerFromDom = $derived(
    banner &&
      (!hasFunctionalRequirements || hasPreviouslyAnswered || hasNavigatedAfterAnswer) &&
      !$administrateCookieChoice
  );

  $effect(() => {
    // A11y, when reopening banner, focus to banner's first focusable element
    const focusable =
      $administrateCookieChoice && !removeBannerFromDom
        ? document?.querySelectorAll(".panel-wrapper :is(a, input, button)")
        : [];
    if (focusable.length > 0) (focusable[0] as HTMLElement).focus();
  });

  $effect(() => {
    if (chosenLevels.includes(4)) trackingAccepted = true;
    else trackingAccepted = false;
  });

  administrateCookieChoice.subscribe((val) => {
    if (val && hideTimeout) clearTimeout(hideTimeout);
    if (val && logTimeout) clearTimeout(logTimeout);
    if (val) {
      hasAnswered = false;
      visuallyHidden = false;
      hasNavigatedAfterAnswer = false;
    }
  });

  consentAnswerStore.subscribe((value: ConsentAnswer) => {
    if (value.includes(4)) {
      storeTrackingLocalStorage(trackingAccepted ? "true" : "false");
    } else {
      storeTrackingLocalStorage("");
    }
  });

  afterNavigate(() => {
    hasNavigatedAfterAnswer = hasAnswered ? true : false;
  });

  /* Checks for if should ask for tracking consent */
  onMount(() => {
    const navigator = (typeof window !== "undefined" ? window.navigator : null) as Navigator & {
      globalPrivacyControl: undefined | boolean;
    };

    hasLocalStorage = storageAvailable("localStorage");
    hasCookiesEnabled = !!navigator?.cookieEnabled;
    hasFunctionalRequirements = hasLocalStorage && hasCookiesEnabled;
    uaNotBlockTracking =
      /* UA GPC status -- proposed spec for browser tracking config. Not widely implemented. */
      !(navigator?.globalPrivacyControl || false) &&
      /* UA DNT status -- deprecated, but still used by many browsers */
      !(navigator?.doNotTrack || false);

    if (hasLocalStorage && typeof prevAnswer === "undefined") {
      prevAnswer = JSON.parse(
        localStorage.getItem(storageLevelKey) || JSON.stringify([])
      ) as ConsentAnswer;
    }

    if (prevAnswer?.length) {
      hasPreviouslyAnswered = true;
      chosenLevels = prevAnswer;
      chosenNotConfirmedLevels = setChosenNotConfirmedLevels(prevAnswer);
      consentAnswerStore.set(prevAnswer);
    } else if (!hasFunctionalRequirements || !uaNotBlockTracking) {
      hasPreviouslyAnswered = true;
      consentAnswerStore.set(minConsent);
    } else {
      // Tracking consent is visible if not removed from DOM
      visuallyHidden = false;
    }
  });
</script>

{#if !removeBannerFromDom}
  <div
    class:panel-wrapper={banner}
    class={classes}
    class:answered-banner={banner && hasAnswered}
    class:sr-only={banner && visuallyHidden}
  >
    <div class:panel={banner} class="grid items-center gap-.5rem laptop:gap-4">
      <p class="my-0 mx-[0_0.5rem] max-w-prose">
        {@render children?.()}
      </p>

      <form action="" class="grid gap-x-.5rem tablet:grid-cols-2">
        <ol class="list-none m-0 p-0 flex flex-wrap gap-x-.5rem">
          {#each consentLevels as [level, { label }]}
            <li>
              <label class="min-h-3rem inline-flex items-center gap-1 text-sm">
                <Checkbox
                  name="add"
                  value={level}
                  bind:state={chosenNotConfirmedLevels[level - 1]}
                  disabled={level === 1}
                />
                {label}
              </label>
            </li>
          {/each}
        </ol>
        <div>
          <p class="my-0 grid grid-cols-3 gap-.5rem children-px-1">
            {#each Object.entries(alternatives) as [val, text]}
              {@const choice = val === "null" ? "null" : val === "true" ? "true" : "false"}
              <button
                type="button"
                class="button whitespace-nowrap min-h-48px text-sm"
                class:button-disabled={!hasFunctionalRequirements}
                onclick={() => {
                  if (hideTimeout) clearTimeout(hideTimeout);

                  setConsentAnswer(choice);
                  hasAnswered = true;
                  administrateCookieChoice.set(false);

                  if (banner) {
                    hideTimeout = setTimeout(() => (visuallyHidden = true), 1000);
                    // time matches CSS animation total time
                  }
                }}
              >
                {text}
              </button>
            {/each}
          </p>
          {#if banner}
            <p class="my-1 text-sm text-center">Cookie-valg kan endres nederst på siden</p>
          {/if}
        </div>
      </form>
    </div>
  </div>
{/if}

{#if banner}
  <!-- Google consent mode requires localStorage, so don't track if not available -->
  <GoogleTagManager track={hasLocalStorage && trackingAccepted} />
  <FacebookPixelScript track={trackingAccepted} />
{/if}

<style lang="postcss">
  .panel-wrapper {
    position: fixed;
    bottom: 0;
    padding-block: 3px 4vh;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(transparent, var(--warning-40));
  }

  .panel {
    margin-inline: var(--space-edge-x);
    border-radius: 1rem;
    width: fit-content;
    max-width: 100%;
    background-color: white;
    box-shadow:
      0 0 0 3px var(--warning),
      0 0 0.5rem 3px #0005;
    padding-block: var(--s-2);
    padding-inline: var(--s-3);
    /* Cookie img (assumes img equal in width and height) */
    --img-size: calc(1.375rem + 1vh);
    background-position: center calc(100% + (var(--img-size) - 1.75rem + 1vh));
    background-size: auto var(--img-size);
  }

  @media (--tablet) {
    .panel {
      /* Cookie img (assumes square img) */
      --img-size: 2.625rem;
      background-position: max(2.5vw, 0.75rem) center;
      padding-block: var(--s-3) var(--s-2);
      padding-inline: max(6vw, 5rem) max(2.5vw, 1rem);
      background-image: url("/_assets/cookie.svg");
    }
  }

  .answered-banner {
    overflow: hidden;
    animation: fade-out 0.5s 0.5s var(--easing) forwards;
    & > * {
      animation: answered-banner 0.75s 0.25s var(--easing) forwards;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes answered-banner {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    75% {
      opacity: 0;
    }
    100% {
      transform: translateY(200%);
    }
  }
</style>
